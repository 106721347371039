import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {
  ClientDelete,
  ClientsList,
  getClientError,
  getClientSuccess, getPackagesListAll, getPaginatedClients,
  IClientState, IPackageState, PackagesListAll,
  ResetClientState
} from '@app/stores';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {takeUntil} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {Client, Package} from '@app/modules/shared/models';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DataTableDirective} from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import moment from 'moment-timezone';
import * as DataTables from 'datatables.net';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  unsubscriber = new Subject();
  clients: Client[] = [];
  dtTrigger: Subject<any> = new Subject();
  ajaxCallback: any;
  searchDelay: any;
  token: any;
  link: string;
  public packages: Package[] = [];
  selectedPackage = 'All';

  constructor(
    private clientStore: Store<IClientState>,
    private toastr: ToastrService,
    private _modalService: NgbModal,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clipboardService: ClipboardService,
    private packageStore: Store<IPackageState>,
  ) {
    this.clientStore.dispatch(ResetClientState({params: {error: '', paginatedClients: null, success: ''}}));
    this.subscribeStore();
  }

  subscribeStore() {
    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastr.error(error, 'Error');
        }
      });
    this.clientStore.pipe(select(getClientSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          this.toastr.success(success, 'Success');
          this.loadAllClients();
        }
      });
    this.clientStore.pipe(select(getPaginatedClients))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(paginatedClients => {
        if (paginatedClients) {
          this.clients = paginatedClients.list;
          if (this.ajaxCallback) {
            this.ajaxCallback({
              recordsTotal: paginatedClients.paging.total,
              recordsFiltered: paginatedClients.paging.total,
              data: []
            });
          }
          this.token = paginatedClients.trial_registration_url;
        }
      });
    this.packageStore.pipe(select(getPackagesListAll))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data) {
          this.packages = data;
        }
      });
  }

  ngAfterViewInit() {
    this.dtTrigger.next(undefined);
  }

  ngOnInit(): void {
    this.datatableSettings();
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api<any>) => {
        dtInstance.destroy();
        this.dtTrigger.next(undefined);
      });
    } else {
      this.dtTrigger.next(undefined);
    }
    this.packageStore.dispatch(PackagesListAll({}));
  }

  datatableSettings() {
    const columns = ['logo', 'name', 'email', 'package', 'package_start_date', 'package_end_date', 'created_at'];
    this.dtOptions = {
      // scrollY: '100vh',
      scrollCollapse: true,
      pagingType: 'full_numbers',
      columnDefs: [{orderable: false, targets: 'no-sort'},
        { width: '130px', targets: 0 },
        { width: '230px', targets: 1 },
        { width: '250px', targets: 2 },
        { width: '150px', targets: 3 },
        { width: '180px', targets: 4 },
        { width: '180px', targets: 5 },
      ],
      responsive: false,
      ordering: true,
      processing: true,
      serverSide: true,
      pageLength: 10,
      paging: true,
      scrollX: true,
      searching: true,
      // scrollY: "100vh",
      fixedHeader: {
        header: true,
        footer: true,
        headerOffset: 55 ,
      },
      fixedColumns: {
        right: 1,
        left: 0
      },
      drawCallback: function (setting) {
        const totalPages = this.api().page.info().pages;
        if (totalPages <= 1) {
          document.querySelector('.dataTables_paginate').classList.add('d-none');
        } else {
          document.querySelector('.dataTables_paginate').classList.remove('d-none');
        }
      },
      ajax: (dataTablesParameters: any, callback) => {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api<any>) => {
          this.ajaxCallback = callback;
          const pageLength = dataTablesParameters.length;
          const pageNumber = (dataTablesParameters.start / pageLength);
          dtInstance.page.len(pageLength);
          const searchBox = $('div.dataTables_filter input');
          searchBox.off('keyup.DT input.DT');
          searchBox.on('keyup', () => {
            const search: any = searchBox.val();
            clearTimeout(this.searchDelay);
            this.searchDelay = setTimeout(() => {
              if (search != null) {
                dtInstance.search(search).draw();
              }
            }, 1000);
          });
          const orderBy = {
            ...dataTablesParameters.order[0],
            column: columns[dataTablesParameters.order[0].column]
          };
          this.loadAllClients(pageNumber, pageLength, dataTablesParameters.search.value, orderBy);
        });
      },
      columns: [
        {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}, {data: null}
      ],
      order : [1, 'asc']
    };
  }

  loadAllClients(page = 0, perPage = 10, search = '', orderBy= {'column': 'name', 'dir': 'asc'}) {
    this.clientStore.dispatch(ClientsList({
      params: {
        options: JSON.stringify({includePackage: true, includePagination: true, orderBy}),
        ...(this.selectedPackage !== 'All' ? {package_id: this.selectedPackage} : {}),
        page: page + 1,
        perPage,
        search
      }
    }));
  }

  onClickProjects(client) {
    // const projectListFilter = JSON.parse(localStorage.getItem('projectFilterList')) ? JSON.parse(localStorage.getItem('projectFilterList')) : {};
    // projectListFilter['selectedClient'] = client.id;
    // projectListFilter['selectedClientName'] = client.name;
    // localStorage.setItem('projectFilterList', JSON.stringify(projectListFilter));
    this.router.navigate(['/projects'],{queryParams: {clientId: client.id}});
  }

  formatMoment(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD[T]HH[:]mm[:]00[.000Z]');
      if (insertTime.isValid()) {
        return insertTime.format('DD-MM-YYYY hh:mm a');
      }
    return null;
  }

  formatMomentDate(insertTime) {
    insertTime = moment(insertTime, 'YYYY-MM-DD');
      if (insertTime.isValid()) {
        return insertTime.format('DD-MM-YYYY');
      }
    return null;
  }

  trialRegister() {
    this.link = `${environment.frontUrl.replace('#', '')}register/${this.token}`;
    return this.link;
  }

  copyLinkToClipboard(): void {
    this.clipboardService.copyFromContent(this.link);
    this.toastr.success('Link is Copied');
  }

  clientListByPackage(event = null) {
    this.selectedPackage = 'All';
    if (event) {
      this.selectedPackage = event.value;
    }
    this.loadAllClients();
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }

  deleteClient(client: Client) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then(result => {
      if (result.value) {
        this.clientStore.dispatch(ClientDelete({clientId: client.id}));
      }
    });
  }

}
