import {Component, OnDestroy, OnInit} from '@angular/core';
import {Client, Package} from '@app/modules/shared/models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {select, Store} from '@ngrx/store';
import moment from 'moment-timezone';
import {TzDatePipe} from '@app/modules/shared/pipes/tz-date.pipe';
import {
  ClientAdd,
  ClientGet,
  ClientUpdate,
  getClient,
  getClientError,
  getClientSuccess,
  getFileUploadProgress,
  getSignedUrl,
  getSignedUrlError, getUploadToSignedUrl,
  getPackagesListAll, getPackagesListAllError,
  getUploadToSignedUrlError,
  IClientState,
  IPackageState,
  IFileUploadState,
  ResetClientState,
  ResetFileState, SignedUrl, UploadToSignedUrl,
  PackagesListAll
} from '@app/stores';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import { Constants } from '@app/consts';

@Component({
  selector: 'app-client-add',
  templateUrl: './client-add.component.html',
  styleUrls: ['./client-add.component.css'],
  providers: [Constants]
})
export class ClientAddComponent implements OnInit, OnDestroy {

  public min: Date = new Date();
  public max: Date = this.min;
  public client: Client;
  public packages: Package[] = [];
  addClientForm: UntypedFormGroup;
  unsubscriber = new Subject();
  public submitted = false;
  public clientId: number;
  imageSrc: any;
  imageFile = null;
  imageSignedUrl = null;
  fileUploadProgress = 0;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private clientStore: Store<IClientState>,
    private packageStore: Store<IPackageState>,
    private toastrService: ToastrService,
    private fileUploadStore: Store<IFileUploadState>,
    public constants: Constants,
    private tzDatePipe: TzDatePipe,
  ) {
    this.clientStore.dispatch(ResetClientState({params: {error: '', success: '', client: null}}));
    this.fileUploadStore.dispatch(ResetFileState({ params: { error: '', file: null } }));
    this.subscribeStores();
  }

  subscribeStores() {
    this.clientStore.pipe(select(getClientError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error);
        }
      });
    this.clientStore.pipe(select(getClientSuccess))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(success => {
        if (success) {
          if (this.submitted) {
            this.submitted = false;
            this.router.navigate(['/clients']);
          }
        }
      });
    this.packageStore.pipe(select(getPackagesListAll))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(data => {
        if (data) {
          this.packages = data;
        }
      });
    this.clientStore.pipe(select(getClient))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(client => {
        if (client) {
          const clientObj =  Object.assign({}, client);
          let fromDate: any = `${(clientObj.package_start_date ? moment(clientObj.package_start_date).format('MM/DD/YYYY') : '') || ''} ${clientObj.package_start_date || ''}`;
          let toDate: any = `${(clientObj.package_end_date ? moment(clientObj.package_end_date).format('MM/DD/YYYY') : '') || ''} ${clientObj.package_end_date || ''}`;
          if (fromDate.trim()) {
            fromDate = this.tzDatePipe.toUTCMomentObject(moment(fromDate.trim(), `MM/DD/YYYY${clientObj.package_start_date ? ' h:m A' : ''}`));
            clientObj.package_start_date = clientObj.package_start_date ? fromDate.format('MM/DD/YYYY') : '';
          } else {
            fromDate = null;
            clientObj.package_start_date = '';
          }
          if (toDate.trim()) {
            toDate = this.tzDatePipe.toUTCMomentObject(moment(toDate.trim(), `MM/DD/YYYY${clientObj.package_end_date ? ' h:m A' : ''}`));
            clientObj.package_end_date = clientObj.package_end_date ? toDate.format('MM/DD/YYYY') : '';
          } else {
            toDate = null;
            clientObj.package_end_date = '';
          }
          this.addClientForm.patchValue({...clientObj});
          this.imageSrc = clientObj.logo || '';
        }
      });
    this.fileStoreSubscribe();
  }

  activeFileInput(fileInput) {
    fileInput.click();
  }

  fileStoreSubscribe() {
    this.fileUploadStore.pipe(select(getSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrlError))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe(error => {
        if (error) {
          this.toastrService.error(error, 'Error');
        }
      });
    this.fileUploadStore.pipe(select(getFileUploadProgress))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data !== undefined && data !== null) {
          this.fileUploadProgress = data;
        }
      });
    this.fileUploadStore.pipe(select(getSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && data.url) {
          this.imageSignedUrl = data.url;
        }
      });
    this.fileUploadStore.pipe(select(getUploadToSignedUrl))
      .pipe(takeUntil(this.unsubscriber))
      .subscribe((data: any) => {
        if (data && this.imageSignedUrl) {
          const logo = this.imageSignedUrl.split('?')[0];
          this.addClientForm.patchValue({logo});
          this.imageSignedUrl = null;
          this.imageFile = null;
          this.saveClientData();
        }
      });
  }

  ngOnInit(): void {
    this.addClientForm = this.formBuilder.group({
      name: ['', Validators.required],
      password: [Math.random().toString(36).slice(-8), Validators.required],
      email: ['', [Validators.required, Validators.email]],
      logo: [''],
      package_id: [null],
      package_start_date: [null],
      package_end_date: [null],
      website: ['', [Validators.required, Validators.pattern(this.constants.urlRegex)]],
      comment: [''],
      login_whitelisted_ips: [null],
      require_google_utm_for_cta_links: [true],
      google_utm_params: [null]
    });
    this.route.paramMap.subscribe(params => {
      if (params.has('id')) {
        this.clientId = +params.get('id');
        this.loadClient(this.clientId);
      }
    });
    this.addClientForm.get('package_start_date').valueChanges.subscribe(value => {
      this.max = value;
      // const endDate = this.addProjectForm.get('end_date').value;
      // if (value && endDate && Math.floor((Date.parse(value) - Date.parse(endDate)) / 86400000) > 0) {
      //   this.addProjectForm.patchValue({end_date: null, end_time: null});
      // }
    });
    this.packageStore.dispatch(PackagesListAll({}));
  }

  onFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      this.imageFile = event.target.files[0];
      this.fileUploadStore.dispatch(SignedUrl({fileName: this.imageFile.name, contentType: this.imageFile.type}));
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(this.imageFile);
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      }).then(data => {
        this.imageSrc = data;
      });
    }
  }

  get f() {
    return this.addClientForm.controls;
  }

  loadClient(clientId: number) {
    this.clientStore.dispatch(ClientGet({clientId}));
  }

  saveClient() {
    this.submitted = true;
    if (this.addClientForm.invalid) {
      return;
    }

    if (this.imageSignedUrl && this.imageFile) {
      this.fileUploadStore.dispatch(UploadToSignedUrl({files: [{url: this.imageSignedUrl, fileData: this.imageFile}]}));
    } else {
      this.saveClientData();
    }
  }

  saveClientData() {
    const data = this.addClientForm.value;
    if (data.package_start_date === '') data.package_start_date = null;
    if (data.package_end_date === '') data.package_end_date = null;
    if (data.login_whitelisted_ips === '') data.login_whitelisted_ips = null;
    if (data.require_google_utm_for_cta_links === '') data.require_google_utm_for_cta_links = 0;
    if (data.google_utm_params === '') data.google_utm_params = null;

    if (this.clientId) {
      this.clientStore.dispatch(ClientUpdate({clientId: this.clientId, client: data}));
    } else {
      this.clientStore.dispatch(ClientAdd({client: data}));
    }
  }

  generateRandomPassword() {
    this.addClientForm.patchValue({password: Math.random().toString(36).slice(-8)});
  }

  packageSelected(e) {
    this.addClientForm.patchValue({package_id: e?.value || null});
  }

  ngOnDestroy() {
    this.unsubscriber.next(undefined);
    this.unsubscriber.complete();
  }

}
